<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">Users Insights</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 col-xl-12">
					<div class="card">
						<div class="card-header justify-content-between">
							<h4 class="card-header-title">Users</h4>
						</div>
						<div class="card-body">
							<bar-chart
								v-if="usersGrowthData"
								class="chart barchart"
								:chart-data="usersGrowthData"
								:options="barChartOptions"
								></bar-chart>
							<div
								v-if="loadingUsersGrowthData"
								class="d-flex justify-content-center align-items-center"
								>
								<div class="spinner-border" role="status"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-xl-6">
					<div class="card">
						<div class="card-header">
							<div class="row align-items-center">
								<div class="col">
									<h4 class="card-header-title">Top 5 Recent Users</h4>
								</div>
							</div>
						</div>
						<div class="">
							<app-table
								:fields="last5UsersFields"
								:items="last5Users.slice(0, 5)"
								:loading="loadingLast5Users"
								:error-loading="errorLoadingLast5Users"
								@retry="fetchLast5Users"
								@row-clicked="viewUser"
								>
								<template v-slot:user="{ item }">
									<div class="avatar">
										<img
											v-if="item.avatar"
											:src="item.avatar"
											alt="..."
											class="avatar-img rounded-circle"
											/>
										<span class="avatar-title rounded-circle" v-else>
											{{ `${item.fname} ${item.lname}` | initials }}
										</span>
									</div>
									<span class="ml-2 font-weight-bold"><router-link class="sh-page-link" :to="{name : 'ShowUser', params : { userId : item.id}}">{{
										`${item.fname} ${item.lname}`
									}}</router-link></span>
								</template>
								<template v-slot:status="{ item }">
									<span :class="item.active ? 'text-green' : 'text-red'">{{
										item.active ? 'Active' : 'Inactive'
									}}</span>
								</template>
							</app-table>
						</div>
						<div class="card-footer card-footer-boxed">
							<div class="row align-items-center justify-content-end">
								<router-link
									:to="{ name: 'UsersList' }"
									class="d-flex align-items-center justify-content-center"
									>
									<small class="">
										All Users <i class="fe fe-arrow-right"></i>
									</small>
								</router-link>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-xl-6">
					<div class="card">
						<div class="card-header">
							<div>
								<h4 class="card-header-title">Wallet Funding</h4>

								<small class="text-muted me-3"
									>Wallet funding by users in the last 30 days</small
									>
							</div>
						</div>
						<div class="card-body">
							<line-chart
								v-if="last30DaysTransactions"
								class="chart barchart"
								:chart-data="last30DaysTransactions"
								:options="lineChartOptions"
								></line-chart>
							<div
								v-if="loadingLast30DaysTransactions"
								class="d-flex justify-content-center align-items-center"
								>
								<div class="spinner-border" role="status"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getYearlyBarChartData } from '@/utils/chart'
import BarChart from '@/components/charts/BarChart.vue'
import AppTable from '@/components/core/AppTable.vue'
import LineChart from '@/components/charts/LineChart.vue'
import { format, parseISO } from 'date-fns'

export default {
  components: {
    BarChart,
    AppTable,
    LineChart
  },
  filters: {
    date (value) {
      if (!value) return ''
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return Intl.DateTimeFormat('en-US', options).format(value)
    }
  },
  data () {
    return {
      tableLoading: false,
      usersGrowthData: null,
      loadingLast30DaysTransactions: true,
      errorLoadingLast30DaysTransactions: false,
      last30DaysTransactions: null,
      loadingUsersGrowthData: true,
      errorLoadingUsersGrowthData: false,
      loadingLast5Users: true,
      errorLoadingLast5Users: false,
      last5Users: [],
      last5TransactionsFields: [
        {
          label: 'Date',
          key: 'created_at'
        },
        {
          label: 'Description',
          key: 'title'
        },
        {
          label: 'User',
          key: 'user'
        },
        {
          label: 'Amount',
          key: 'amount'
        }
      ],
      last5UsersFields: [
        {
          label: 'User',
          key: 'user'
        },
        {
          label: 'Email',
          key: 'email'
        },
        {
          label: 'Status',
          key: 'status'
        }
      ],
      barChartOptions: {
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              },
              offset: true
            }
          ],
          yAxes: [
            {
              gridLines: {
                borderDash: [8, 4],
                color: '#F6F6F7'
              }
            }
          ]
        }
      },
      lineChartOptions: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              },
              offset: true
            }
          ],
          yAxes: [
            {
              gridLines: {
                borderDash: [8, 4],
                color: '#F6F6F7'
              },
              ticks: {
                stepSize: 50000,
                beginAtZero: true,
                scaleBeginAtZero: true
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const dataset = data.datasets[tooltipItem.datasetIndex]

              const total = dataset.data.reduce(function (
                previousValue,
                currentValue
              ) {
                return previousValue + currentValue
              })

              return `₦${this.$options.filters.money(total)}`
            }
          }
        }
      }
    }
  },
  created () {
    this.fetchChartData()
    this.fetchLast5Users()
    this.fetchTransactionsChartData()
  },
  methods: {
    fetchChartData () {
      this.loadingUsersGrowthData = true
      this.errorLoadingUsersGrowthData = false

      this.axios
        .get('/v1/users/graph')
        .then((res) => {
          this.usersGrowthData = getYearlyBarChartData(res.data)
        })
        .catch(() => {
          this.errorLoadingUsersGrowthData = true
        })
        .finally(() => (this.loadingUsersGrowthData = false))
    },
    fetchLast5Users () {
      this.loadingLast5Users = true
      this.errorLoadingLast5Users = false

      this.axios
        .get('/v1/users?limit=5')
        .then((res) => {
          this.last5Users = res.data
        })
        .catch(() => {
          this.errorLoadingLast5Users = true
        })
        .finally(() => (this.loadingLast5Users = false))
    },
    fetchTransactionsChartData () {
      this.loadingLast30DaysTransactions = true
      this.errorLoadingLast30DaysTransactions = false

      this.axios
        .get('/v1/transactions/graph')
        .then((res) => {
          this.setTransactionsChartData(res.data)
        })
        .catch(() => {
          this.errorLoadingLast30DaysTransactions = true
        })
        .finally(() => (this.loadingLast30DaysTransactions = false))
    },
    setTransactionsChartData (data) {
      const credits = []

      const mappedData = {}

      for (const datum of data) {
        const dayInMonth = format(parseISO(datum.created_at), 'd-MM')

        if (mappedData[dayInMonth] && mappedData[dayInMonth][datum.type]) {
          mappedData[dayInMonth][datum.type] =
            mappedData[dayInMonth][datum.type] + parseFloat(datum.amount)
        } else {
          mappedData[dayInMonth] = {
            ...mappedData[dayInMonth],
            [datum.type]: parseFloat(datum.amount)
          }
        }
      }

      Object.values(mappedData).forEach((datum) => {
        if (datum.credit) {
          credits.push(datum.credit)
        }
      })

      this.last30DaysTransactions = {
        labels: Object.keys(mappedData),
        datasets: [
          {
            label: 'Wallet Funding',
            backgroundColor: 'transparent',
            borderColor: '#00e069',
            data: credits
          }
        ]
      }
    },
    viewUser () {}
  }
}
</script>

<style lang="scss" scoped>
$primary: #01e069 !default;
$black: #060606 !default;

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: transparent;
  box-shadow: 0 3px 0 0px $primary;
  color: $black;
  font-weight: 800;
}

.nav-pills .nav-link,
.nav-pills .show>.nav-link {
  border-radius: 0;
}

.nav-link.active {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
